<template>
  <div style="margin: 20px">
    <div class="form-group">
      <label class="control-label">{{ $t('promotionLabel') }}:</label>
      <multiselect
        :placeholder="$t('selectPromotionPlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :options="promotions"
        :value="selectedPromotion"
        label="name"
        track-by="id"
        :custom-label="promotionsLabel"
        :disabled="readOnly"
        @input="onSelect"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="selectedPromotion" class="form-group">
      <label class="control-label">{{ $t('promotionActionLabel') }}:</label>
      <multiselect
        placeholder="Select Action"
        :options="events"
        :value="selectedEvent"
        label="label"
        :disabled="readOnly"
        @input="onInput"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="action.promotion_amount_required" class="form-group">
      <label class="control-label">{{ $t('promotionAmountLabel') }}:</label>
      <div style="margin-bottom: 10px">
        <select2
          v-model="amountType"
          class="form-control"
          :disabled="readOnly"
          @input="onAmountTypeChange"
        >
          <option value="custom">
            {{ $t('promotionAmountCustomAmountOption') }}
          </option>
          <option value="attribute">
            {{ $t('promotionAmountProfileAttributeOption') }}
          </option>
        </select2>
      </div>
      <input
        v-if="amountType === 'custom'"
        v-model="action.promotion_amount"
        class="form-control"
        type="number"
        :disabled="readOnly"
        @input="onAmountInput"
        @change="onAmountChange"
      />
      <multiselect
        v-if="amountType === 'attribute'"
        :placeholder="$t('selectProfileAttributePlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :value="selectedNumberProfileAttribute"
        :options="numberProfileAttributes"
        track-by="id"
        label="name"
        :disabled="readOnly"
        @input="onAmountAttributeChange"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="action.promotion_duration_required" class="form-group">
      <label class="control-label">{{ $t('promotionDurationLabel') }}:</label>
      <input
        v-model="action.promotion_duration"
        class="form-control"
        type="number"
        :disabled="readOnly"
        @input="onDurationInput"
        @change="onDurationChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  data() {
    return {
      event: [
        { id: 1, label: this.$t('promotionEventAddLabel') },
        { id: 2, label: this.$t('promotionEventRemoveLabel') },
        { id: 3, label: this.$t('promotionEventCreditLabel') }
      ],
      amountType:
        this.action.promotion_amount_attribute_title &&
        this.action.promotion_amount_attribute_title.length
          ? 'attribute'
          : 'custom'
    }
  },

  computed: {
    events() {
      let promotion = this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id === this.action.promotion_id
      )
      let event = []
      if (promotion) {
        if (promotion.can_add) {
          event.push({ id: 1, label: this.$t('promotionEventAddLabel') })
        }
        if (promotion.can_remove) {
          event.push({ id: 2, label: this.$t('promotionEventRemoveLabel') })
        }
        if (promotion.can_credit) {
          event.push({ id: 3, label: this.$t('promotionEventCreditLabel') })
        }
      }
      return event
    },

    promotions() {
      return this.$store.state.project.workflowPromotions.filter(
        (promotions) =>
          promotions.can_add || promotions.can_remove || promotions.can_credit
      )
    },

    selectedPromotion() {
      return this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id === this.action.promotion_id
      )
    },

    selectedEvent() {
      return this.event.find((event) => event.id === this.action.event)
    },

    numberProfileAttributes() {
      return this.$store.state.project.numberProfileAttributes
    },

    selectedNumberProfileAttribute() {
      return this.$store.state.project.numberProfileAttributes.find(
        (attr) => attr.name === this.action.promotion_amount_attribute_title
      )
    }
  },

  created() {
    this.$store.dispatch('fetchWorkflowPromotions')
    this.$store.dispatch('fetchNumberProfileAttributes')
  },

  methods: {
    promotionsLabel(option) {
      return option.integration.name + " (#" + option.external_id + ") - " + option.name
    },

    onSelect(option) {
      if (!option) {
        return
      }

      let event = this.defaultPromotionEvent(option)
      let action = this.promotionActionName(event)

      this.workflowApi.updateAction(this.action.id, {
        promotion_id: option.id,
        event: event,
        promotion_amount: 0,
        promotion_amount_attribute_title: null,
        promotion_duration: 0,
        promotion_amount_required: this.isParamRequired(
          option,
          action,
          'amount'
        ),
        promotion_duration_required: this.isParamRequired(
          option,
          action,
          'duration'
        )
      })
    },

    onInput(option) {
      if (!option) {
        return
      }

      let action = this.promotionActionName(option.id)
      this.workflowApi.updateAction(this.action.id, {
        event: option.id,
        promotion_amount: 0,
        promotion_amount_attribute_title: null,
        promotion_duration: 0,
        promotion_amount_required: this.isParamRequired(
          this.selectedPromotion,
          action,
          'amount'
        ),
        promotion_duration_required: this.isParamRequired(
          this.selectedPromotion,
          action,
          'duration'
        )
      })
    },

    onAmountChange(event) {
      const value = event.target.value

      if (!value) {
        this.action.promotion_amount = 0
        return
      }

      this.action.promotion_amount = Number.parseFloat(value).toFixed(2)
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: this.action.promotion_amount
      })
    },

    onAmountAttributeChange(option) {
      if (!option) {
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_amount_attribute_title: option.name
      })
    },

    onAmountTypeChange() {
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: 0,
        promotion_amount_attribute_title: null
      })
    },

    onDurationChange(event) {
      const value = event.target.value

      if (!value) {
        this.action.promotion_duration = 1
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_duration: this.action.promotion_duration
      })
    },

    onAmountInput(event) {
      if (event.target.value.toString().length > 10) {
        this.action.promotion_amount = +event.target.value
          .toString()
          .substring(0, 10)
      }
    },

    onDurationInput(event) {
      if (event.target.value.toString().length > 10) {
        this.action.promotion_duration = +event.target.value
          .toString()
          .substring(0, 10)
      }
    },

    defaultPromotionEvent(promotion) {
      if (promotion.can_add) {
        return 1
      } else if (promotion.can_credit) {
        return 3
      } else if (promotion.can_remove) {
        return 2
      }
    },

    promotionActionName(event) {
      switch (event) {
        case 1:
          return 'add'
        case 2:
          return 'remove'
        case 3:
          return 'credit'
      }
    },

    isParamRequired(promotion, action, param) {
      if (!promotion.params || !promotion.params[param]) {
        return false
      }

      if (!action) {
        return false
      }

      return promotion.params[param].action === action
    }
  }
}
</script>
