
export default Object.freeze({
    pusher: {
        appKey: 'caf36d8c51d31183f526',
        cluster: 'us2'
    },

    xpBaseUrl: 'https://dashboard.fanatics.xtremepush.com',
    xpApiBaseUrl: 'https://rest.fanatics.xtremepush.com'
});
